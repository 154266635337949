import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`


  html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'SpaceGrotesk';
  background-color: #f2f2f2;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body, #app {
  width: 100%;
  height: 100%;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

h4 {
  margin-bottom: 1rem;
}

.pages {
  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    margin: 1rem 0;

  }
}

main {
  margin: 0 auto;
  max-width: 1600px;
}
`

export default GlobalStyle
